<!-- @format -->
<script lang="ts">
  import { getStoresContext, type StoresKey } from "~/stores";
  import { getFragmentConfigOr } from "~/components/field-utils";
  import { ANY_TAG, filter_by_category } from "../store";
  import Dropdown from "./Dropdown.svelte";
  import { propsToCustomProperties } from "~/util/style";

  export let request_store_key: StoresKey;

  export let fragment: Fragment;

  const stores_context = getStoresContext(request_store_key);
  const { T, offers_ext } = stores_context;

  $: ({ items } = $offers_ext);
  $: ({ tags } = $offers_ext);

  type Config = {
    textColor: string;
    backgroundColor: string;
    activeTextColor: string;
    activeBackgroundColor: string;
  }

  const config: Config = {
    textColor: 
      getFragmentConfigOr("textColor", fragment, "var(--brandColor1)"),
    backgroundColor: 
      getFragmentConfigOr("backgroundColor", fragment, "var(--brandColor2)"),
    activeTextColor: 
      getFragmentConfigOr("activeTextColor", fragment, "var(--brandColor2)"),
    activeBackgroundColor: 
      getFragmentConfigOr("activeBackgroundColor", fragment, "var(--brandColor1)")
  }

  $: t = $T;
  function action(id: number) {
    filter_by_category.set(id);
  }
</script>

{#if items && items.length}
  <div class="container" style={propsToCustomProperties(config)} >
    <div
      class="program-filter__container d-md-flex justify-content-center d-none d-md-block w-100 mb-4">
      <button
        class="program-filter__btn active"
        class:active={$filter_by_category == ANY_TAG}
        on:click={() => action(ANY_TAG)}>
        {t`offers.allCategories`}
      </button>
      {#each tags as category}
        <button
          class="program-filter__btn"
          on:click={() => action(category.id)}
          data-categoryId={category.id}
          class:active={$filter_by_category == category.id}>
          {category.name}
        </button>
      {/each}
    </div>
    <Dropdown color={config.activeTextColor} bg={config.activeBackgroundColor}>
      <svelte:fragment slot="dropDownTitle">
          {$filter_by_category == ANY_TAG ? t`offers.allCategories` : tags.find(tag => tag.id == $filter_by_category)?.name}
      </svelte:fragment>
      <svelte:fragment slot="resetFilter">
        <button class="dropdown-item-button" on:click={() => action(ANY_TAG)}>
          {t`offers.allCategories`}
        </button>
      </svelte:fragment>
      <svelte:fragment slot="dropDownItems">
        {#if tags}
          {#each tags as category}
            <div class="dropdown-item">
              <button class="dropdown-item-button" 
                class:active={$filter_by_category == category.id}
                on:click={() => action(category.id)}>
                {category.name}
              </button>
            </div>
          {/each}
        {/if}
      </svelte:fragment>
    </Dropdown>
  </div>
{/if}

<style lang="scss">
  .dropdown-item-button{
    display: block;
    width: 100%;
    background: none;
    border: none;
    background-color: var(--backgroundColor);
    color: var(--textColor);

  }
  .dropdown-item-button.active{
    color: var(--activeTextColor) !important;
    background-color: var(--activeBackgroundColor);
  }

  .program-filter__container {
    flex-wrap: wrap;
  }
  button {
    border: none;
    background: none;

  }

  .program-filter__btn {
    padding: 10px;
    margin: 7px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: var(--backgroundColor);
    margin: 0;
    color: var(--textColor);
  }
  .program-filter__btn.active,
  .program-filter__btn:hover {
    color: #fff;
    background-color: #000;
    cursor: pointer;
    text-decoration: underline;
    color: var(--activeTextColor) !important;
    background-color: var(--activeBackgroundColor);
  }
  @media only screen and (max-width: 576px) {
    .program-filter__btn {
      width: 90vw;
      margin: 2px;
      text-align: center;
    }
  }
</style>
